import _ from "lodash";
import { DCSGroupData, DCSUnit, FreqBand, UnitCategory } from "../types";

export interface ModuleRadio {
  label: string;
  bands: FreqBand[];
  presetCount?: number;
  short: string;
  usesChannelNames?: boolean;
}

export enum RadioFreqRange {
  UHF = "UHF",
  VHF = "VHF",
  MIXED = "MIXED",
  FM = "FM",
}

type LaserFormatter = {
  fieldName: string;
  formatter: (unit: DCSUnit) => string;
};

// Key is the name of the field, value is the corresponding miz field.
type LaserConfigField = Record<string, LaserFormatter>;

export type MizLaserFormatter = (props, entry: Record<string, string>) => void;

export type Module = {
  radios?: ModuleRadio[];
  label: string;
  // Deprecated. Planner laser input is more complex that a single bool.
  programmableLaser: boolean;
  radioPresetOrder: RadioFreqRange[];
  category?: UnitCategory;
  laserConfigFields?: LaserConfigField;
  mizLaserFormatter?: MizLaserFormatter;
  hasMultiCrew?: boolean;
  supportsNavPoints?: boolean;
  fuel?: {
    internal: number;
    externalCapacity: number;
    maxExternalTanks: number;
    engineCount: number;
    defaultValues: {
      groundSpeed: number;
      fuelFlow: number;
      externalTanks: number;
      bingo: number;
    };
  };
};
export interface Modules {
  [name: string]: Module;
}

const defaultLaserCodeFormatter = (keyPrefix = "LaserCode"): LaserFormatter => {
  return {
    fieldName: keyPrefix,
    formatter: (unit: DCSUnit) => {
      if (!unit.aircraftProps) {
        return "";
      }
      return [
        unit.aircraftProps[`${keyPrefix}100`],
        unit.aircraftProps[`${keyPrefix}10`],
        unit.aircraftProps[`${keyPrefix}1`],
      ].join("");
    },
  };
};

export const defaultMizLaserFormatter = (keyPrefix = "LaserCode") => (
  props,
  entry: Record<string, string>
) => {
  let code = entry[keyPrefix];

  const str = code.toString();
  const codeArray = str.split("");

  if (codeArray.length !== 3) {
    throw new Error("Invalid laser code length. Must be 3 digits.");
  }

  props[`${keyPrefix}1000`] = 1;
  props[`${keyPrefix}100`] = parseInt(codeArray[0]);
  props[`${keyPrefix}10`] = parseInt(codeArray[1]);
  props[`${keyPrefix}1`] = parseInt(codeArray[2]);
};

export function getTypeForGroup(group: DCSGroupData): string {
  return _.get(group, ["units", 0, "type"]);
}

export function getModuleForGroup(group: DCSGroupData): Module {
  const t = getTypeForGroup(group);

  return _.get(modules, t);
}

export function getLabelForModule(m: string): string {
  return _.get(modules, [m, "label"]);
}

export const modules = {
  "FA-18C_hornet": {
    radios: [
      {
        label: "COMM 1: ARC-210",
        bands: [FreqBand.UHF, FreqBand.VHF, FreqBand.VHF_FM],
        short: "COM1",
      },
      {
        label: "COMM 2: ARC-210",
        bands: [FreqBand.UHF, FreqBand.VHF, FreqBand.VHF_FM],
        short: "COM2",
      },
    ],
    label: "F/A-18C",
    programmableLaser: false,
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.MIXED],
    fuel: {
      internal: 10803,
      externalCapacity: 2244,
      maxExternalTanks: 3,
      engineCount: 2,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 4000,
        externalTanks: 2,
      },
    },
  },
  "M-2000C": {
    radios: [
      {
        label: "UHF Radio",
        bands: [FreqBand.UHF],
        short: "UHF",
      },
      {
        label: "V/UHF Radio",
        bands: [FreqBand.UHF, FreqBand.VHF],
        short: "V/UHF",
      },
    ],
    label: "M-2000C",
    programmableLaser: true,
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.MIXED],
    laserConfigFields: {
      "Laser Code (1xxx)": defaultLaserCodeFormatter(),
    },
    mizLaserFormatter: defaultMizLaserFormatter(),
    supportsNavPoints: true,
    fuel: {
      internal: 6978,
      externalCapacity: 3843,
      maxExternalTanks: 2,
      engineCount: 1,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 2000,
        externalTanks: 2,
      },
    },
  },
  "F-14A-135-GR": {
    radios: [
      { label: "UHF AN/ARC-159", bands: [FreqBand.UHF], short: "UHF" },
      {
        label: "VHF/UHF AN/ARC-182",
        bands: [FreqBand.UHF, FreqBand.VHF, FreqBand.VHF_FM],
        short: "V/UHF",
      },
    ],
    label: "F-14A-135-GR",
    programmableLaser: true,
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.MIXED],
    laserConfigFields: {
      "Laser Code (1xxx)": defaultLaserCodeFormatter("LGB"),
    },
    mizLaserFormatter: defaultMizLaserFormatter("LGB"),
    hasMultiCrew: true,
    supportsNavPoints: true,
    fuel: {
      internal: 16200,
      externalCapacity: 2000,
      maxExternalTanks: 2,
      engineCount: 2,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 4000,
        externalTanks: 2,
      },
    },
  },
  "F-14B": {
    radios: [
      { label: "UHF AN/ARC-159", bands: [FreqBand.UHF], short: "UHF" },
      {
        label: "VHF/UHF AN/ARC-182",
        bands: [FreqBand.UHF, FreqBand.VHF, FreqBand.VHF_FM],
        short: "V/UHF",
      },
    ],
    label: "F-14B",
    programmableLaser: true,
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.MIXED],
    laserConfigFields: {
      "Laser Code": defaultLaserCodeFormatter("LGB"),
    },
    mizLaserFormatter: defaultMizLaserFormatter("LGB"),
    hasMultiCrew: true,
    supportsNavPoints: true,
    fuel: {
      internal: 16200,
      externalCapacity: 2000,
      maxExternalTanks: 2,
      engineCount: 2,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 4000,
        externalTanks: 2,
      },
    },
  },
  "F-4E-45MC": {
    label: "F-4E",
    hasMultiCrew: true,
    radios: [
      {
        label: "UHF AN/ARC-164",
        short: "UHF 1",
        bands: [FreqBand.UHF],
      },
      {
        label: "UHF AN/ARC-164",
        short: "UHF 2",
        bands: [FreqBand.UHF],
      },
    ],
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.UHF],
    laserConfigFields: {
      "Laser Code (1xxx)": defaultLaserCodeFormatter(),
    },
    programmableLaser: true,
    mizLaserFormatter: (props, entry: Record<string, string>) => {
      const val = entry["LaserCode"];
      const [d1, d2, d3] = val.toString().split("");
      props["LaserCodeDigit1"] = 1;
      props["LaserCodeDigit2"] = d1;
      props["LaserCodeDigit3"] = d2;
      props["LaserCodeDigit4"] = d3;
    },
    supportsNavPoints: true,
    fuel: {
      internal: 12149,
      externalCapacity: 2410,
      maxExternalTanks: 2,
      engineCount: 2,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 4000,
        externalTanks: 2,
      },
    },
  },
  "F-16C_50": {
    radios: [
      {
        label: "COMM 1 (UHF): AN/ARC-164",
        short: "COM1 UHF",
        bands: [FreqBand.UHF],
      },
      {
        label: "COMM 2 (VHF): AN/ARC-222",
        short: "COM2 VHF",
        bands: [FreqBand.VHF, FreqBand.VHF_FM],
      },
    ],
    label: "F-16C",
    programmableLaser: true,
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.VHF],
    laserConfigFields: {
      "Laser Code (1xxx)": defaultLaserCodeFormatter(),
    },
    mizLaserFormatter: defaultMizLaserFormatter(),
    fuel: {
      internal: 7200,
      externalCapacity: 2450,
      maxExternalTanks: 2,
      engineCount: 1,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 2000,
        externalTanks: 2,
      },
    },
  },
  "JF-17": {
    radios: [
      {
        label: "COMM 1/2 Preset",
        bands: [FreqBand.UHF, FreqBand.VHF],
        short: "COMM 1/2",
      },
    ],
    label: "JF-17",
    programmableLaser: true,
    radioPresetOrder: [RadioFreqRange.MIXED],
    laserConfigFields: {
      "Laser Code (1xxx)": defaultLaserCodeFormatter(),
    },
    mizLaserFormatter: defaultMizLaserFormatter(),
    supportsNavPoints: true,
  },
  "A-10C": {
    radios: [
      {
        label: "VHF AM: ARC-186",
        bands: [FreqBand.VHF],
        short: "COM 1",
        presetCount: 20,
        usesChannelNames: true,
      },
      {
        label: "UHF AM: ARC-164",
        bands: [FreqBand.UHF],
        short: "COM 2",
        presetCount: 20,
      },
      {
        label: "VHF FM: ARC-186",
        bands: [FreqBand.VHF_FM],
        short: "COM 3",
        presetCount: 20,
      },
    ],
    label: "A-10C",
    programmableLaser: false,
    radioPresetOrder: [
      RadioFreqRange.VHF,
      RadioFreqRange.UHF,
      RadioFreqRange.FM,
    ],
  },
  "A-10C_2": {
    radios: [
      {
        label: "UHF/VHF ARC-210",
        bands: [FreqBand.UHF, FreqBand.VHF],
        short: "COM 1",
        presetCount: 25,
        usesChannelNames: true,
      },
      {
        label: "UHF AM: ARC-164",
        bands: [FreqBand.UHF],
        short: "COM 2",
        presetCount: 20,
      },
      {
        label: "VHF FM: ARC-186",
        bands: [FreqBand.VHF_FM],
        short: "COM 3",
        presetCount: 20,
      },
    ],
    label: "A-10C II",
    programmableLaser: false,
    radioPresetOrder: [
      RadioFreqRange.MIXED,
      RadioFreqRange.UHF,
      RadioFreqRange.FM,
    ],
  },
  AV8BNA: {
    radios: [
      {
        label: "V/UHF Radio 1",
        bands: [FreqBand.UHF, FreqBand.VHF],
        presetCount: 26,
        short: "V/UHF 1",
      },
      {
        label: "V/UHF Radio 2",
        bands: [FreqBand.UHF, FreqBand.VHF],
        presetCount: 26,
        short: "V/UHF 2",
      },
      {
        label: "V/UHF RCS Presets",
        bands: [FreqBand.UHF, FreqBand.VHF, FreqBand.VHF_FM],
        short: "RCS",
      },
    ],
    label: "AV-8B",
    programmableLaser: true,
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.MIXED],
    laserConfigFields: {
      "Laser code for APKWS": defaultLaserCodeFormatter(),
      "Laser code for GBUs": defaultLaserCodeFormatter("GBULaserCode"),
    },
    mizLaserFormatter: (props, entry: Record<string, string>) => {
      const f1 = defaultMizLaserFormatter();
      const f2 = defaultMizLaserFormatter("GBULaserCode");

      f1(props, entry);
      f2(props, entry);
    },
    supportsNavPoints: true,
  },
  "Mi-24P": {
    radios: [
      {
        label: "R-863",
        bands: [FreqBand.UHF, FreqBand.VHF],
        presetCount: 20,
        short: "R-863",
      },
      {
        label: "R-828",
        bands: [FreqBand.VHF_FM],
        presetCount: 10,
        short: "R-828",
      },
    ],
    label: "Mi-24P",
    programmableLaser: false,
    radioPresetOrder: [RadioFreqRange.MIXED, RadioFreqRange.FM],
  },
  "Mi-8MT": {
    radios: [
      {
        label: "R-863",
        bands: [FreqBand.UHF, FreqBand.VHF],
        presetCount: 20,
        short: "R-863",
      },
      {
        label: "R-828",
        bands: [FreqBand.VHF_FM],
        presetCount: 10,
        short: "R-828",
      },
    ],
    label: "Mi-8MT",
    programmableLaser: false,
    radioPresetOrder: [RadioFreqRange.MIXED, RadioFreqRange.FM],
  },
  "ka-50": {
    radios: [
      { label: "R-828", bands: [FreqBand.VHF_FM], short: "R-828" },
      { label: "ARK-22", bands: [FreqBand.UHF], short: "ARK-22" },
    ],
    label: "Ka-50P",
    programmableLaser: false,
    radioPresetOrder: [RadioFreqRange.FM, RadioFreqRange.UHF],
    supportsNavPoints: true,
  },
  "Uh-1h": {
    radios: [{ label: "UHF AN/ARC-51", bands: [FreqBand.UHF], short: "UHF" }],
    label: "UH-1H",
    programmableLaser: false,
    radioPresetOrder: [RadioFreqRange.UHF],
  },
  "AH-64D_BLK_II": {
    radios: [
      {
        label: "ARC-186 (VHF)",
        bands: [FreqBand.VHF],
        presetCount: 10,
        short: "VHF",
      },
      {
        label: "ARC-164 (UHF)",
        bands: [FreqBand.UHF],
        presetCount: 10,
        short: "UHF",
      },
      {
        label: "FM 1: ARC-201D",
        bands: [FreqBand.VHF_FM],
        presetCount: 10,
        short: "FM1",
      },
      {
        label: "FM 2: ARC-201D",
        bands: [FreqBand.VHF_FM],
        presetCount: 10,
        short: "FM2",
      },
    ],
    label: "AH-64D",
    hasMultiCrew: true,
    programmableLaser: false,
    radioPresetOrder: [
      RadioFreqRange.VHF,
      RadioFreqRange.UHF,
      RadioFreqRange.FM,
      RadioFreqRange.FM,
    ],
    category: UnitCategory.Helicopter,
    fuel: {
      internal: 2513,
      externalCapacity: 657,
      maxExternalTanks: 1,
      engineCount: 2,
      defaultValues: {
        groundSpeed: 120,
        fuelFlow: 335,
        bingo: 250,
        externalTanks: 1,
      },
    },
  },
  "UH-60L": {
    label: "UH-60L",
    radios: [
      {
        label: "AN/ARC-201 (1)",
        bands: [FreqBand.VHF],
        presetCount: 7,
        short: "",
      },
      {
        label: "AN/ARC-164 (V)",
        bands: [FreqBand.UHF],
        presetCount: 20,
        short: "",
      },
      {
        label: "AN/ARC-186 (V)",
        bands: [FreqBand.VHF],
        presetCount: 2,
        short: "",
      },
      {
        label: "AN/ARC-201 (2)",
        bands: [FreqBand.VHF],
        presetCount: 7,
        short: "",
      },
      {
        label: "AN/ARC-220",
        bands: [FreqBand.HF],
        presetCount: 2,
        short: "HF",
      },
    ],
    programmableLaser: false,
    radioPresetOrder: [
      RadioFreqRange.VHF,
      RadioFreqRange.UHF,
      RadioFreqRange.VHF,
      RadioFreqRange.UHF,
    ],
  },
  "F-15ESE": {
    label: "F-15E",
    // This field is now legacy. Used for rendering kneeboards.
    programmableLaser: false,
    supportsNavPoints: true,
    radios: [
      {
        label: "UHF Radio 1",
        bands: [FreqBand.UHF],
        presetCount: 20,
        short: "COM 1",
      },
      {
        label: "V/UHF Radio 2",
        bands: [FreqBand.UHF, FreqBand.VHF],
        presetCount: 20,
        short: "COM 2",
      },
    ],
    radioPresetOrder: [RadioFreqRange.UHF, RadioFreqRange.MIXED],
    mizLaserFormatter: (props, entry: Record<string, string>) => {
      for (const key in entry) {
        props[key] = _.toNumber(entry[key]);
      }
    },
    hasMultiCrew: true,
    laserConfigFields: {
      "Station 2 (1xxx)": {
        fieldName: "Sta2LaserCode",
        formatter: (unit: DCSUnit) =>
          _.toString(unit.aircraftProps["Sta2LaserCode"]),
      },
      "Left CFT (1xxx)": {
        fieldName: "LCFTLaserCode",
        formatter: (unit: DCSUnit) =>
          _.toString(unit.aircraftProps["LCFTLaserCode"]),
      },
      "Station 5 (1xxx)": {
        fieldName: "Sta5LaserCode",
        formatter: (unit: DCSUnit) =>
          _.toString(unit.aircraftProps["Sta5LaserCode"]),
      },
      "Right CFT (1xxx)": {
        fieldName: "RCFTLaserCode",
        formatter: (unit: DCSUnit) =>
          _.toString(unit.aircraftProps["RCFTLaserCode"]),
      },

      "Station 8 (1xxx)": {
        fieldName: "Sta8LaserCode",
        formatter: (unit: DCSUnit) =>
          _.toString(unit.aircraftProps["Sta8LaserCode"]),
      },
    },
    fuel: {
      internal: 22588,
      externalCapacity: 3965,
      maxExternalTanks: 3,
      engineCount: 2,
      defaultValues: {
        groundSpeed: 480,
        fuelFlow: 6000,
        bingo: 4000,
        externalTanks: 2,
      },
    },
  },
  OH58D: {
    label: "OH-58D",
    programmableLaser: false,
    supportsNavPoints: true,
    hasMultiCrew: true,
    radios: [
      {
        label: "UHF AM",
        bands: [FreqBand.UHF],
        presetCount: 20,
        short: "UHF",
      },
      {
        label: "VHF",
        bands: [FreqBand.VHF],
        presetCount: 20,
        short: "VHF",
      },
      {
        label: "VHF FM1",
        bands: [FreqBand.VHF_FM],
        presetCount: 20,
        short: "VHF FM1",
      },
      {
        label: "VHF FM2",
        bands: [FreqBand.VHF_FM],
        presetCount: 20,
        short: "VHF FM2",
      },
    ],
    radioPresetOrder: [
      RadioFreqRange.UHF,
      RadioFreqRange.VHF,
      RadioFreqRange.FM,
      RadioFreqRange.FM,
    ],
    fuel: {
      internal: 736,
      externalCapacity: 0,
      maxExternalTanks: 0,
      engineCount: 1,
      defaultValues: {
        groundSpeed: 100,
        fuelFlow: 370,
        bingo: 250,
        externalTanks: 1,
      },
    },
  },
};

export type AicraftPropTypes = {
  "F-15ESE": {
    HumanOrchestra: boolean;
    InitAirborneTime: number;
    InitAlertStatus: boolean;
    LCFTLaserCode: number;
    MountNVG: boolean;
    needsGCAlign: boolean;
    NetCrewControlPriority: number;
    RCFTLaserCode: number;
    SoloFlight: boolean;
    Sta2LaserCode: number;
    Sta5LaserCode: number;
    Sta8LaserCode: number;
  };
  AV8BNA: {
    AAR_Zone1: number;
    AAR_Zone2: number;
    AAR_Zone3: number;
    ClockTime: number;
    EWDispenserBL: number;
    EWDispenserBR: number;
    EWDispenserTBL: number;
    EWDispenserTBR: number;
    EWDispenserTFL: number;
    EWDispenserTFR: number;
    GBULaserCode1: number;
    GBULaserCode10: number;
    GBULaserCode100: number;
    LaserCode1: number;
    LaserCode10: number;
    LaserCode100: number;
    LoadWater: boolean;
    MountNVG: boolean;
    RocketBurst: number;
  };
  "F-14B": {
    LGB100: 6;
    M61BURST: 0;
    IlsChannel: 1;
    LGB1: 8;
    KY28Key: 1;
    TacanBand: 0;
    ALE39Loadout: 0;
    UseLAU138: true;
    LGB10: 8;
    INSAlignmentStored: false;
    TacanChannel: 0;
    LGB1000: 1;
  };
};
