import { Alert, AlertTitle, Box, Breadcrumbs, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import AdminRadios from "../components/AdminRadios/AdminRadios";
import DrawingEditors from "../components/DrawingEditors/DrawingEditors";
import ErrorPage from "../components/ErrorPage";
import ExternalData from "../components/ExternalData/ExternalData";
import Flex from "../components/Flex";
import FlexChild from "../components/FlexChild";
import PublishOptions from "../components/FragOrderDetails/PublishOptions";
import PublishStatus from "../components/FragOrderDetails/PublishStatus";
import JobsPage from "../components/JobsPage/JobsPage";
import KneeboardManagement from "../components/Kneeboards/KneeboardManagement";
import Link from "../components/Link";
import MizFileDetails from "../components/MizFileDetails/MissionFileDetails";
import Page from "../components/Page";
import PlanningManagementForm from "../components/PlanningManager";
import SignupManagement from "../components/SignupManagement";
import SubRouterTabs, { RouterTab } from "../components/SubRouterTabs";
import { FeatureFlag } from "../contexts/FeatureFlagsContext";
import { useGetPublishStatus } from "../contexts/PublishManagerContext";
import { useGetFragOrder } from "../hooks/frag_orders";
import { AppRoutes } from "../lib/types";

type Props = {
  className?: string;
};

function FragOrderDetail({ className }: Props) {
  const { id } = useParams();
  const { data: fragOrder, isLoading, error } = useGetFragOrder(id as string);
  const { data: publishStatus } = useGetPublishStatus(id);
  const { url } = useRouteMatch();

  const isInvalidPublishManfest = _.some(publishStatus, (p) => p.version > 2);

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  return (
    <Page
      title={
        <Breadcrumbs>
          <Link to={AppRoutes.Home}>Frag Orders</Link>
          <div>
            <Typography fontSize="1.5em" component="h2">
              {fragOrder?.name}
            </Typography>
          </div>
        </Breadcrumbs>
      }
      loading={isLoading}
      className={className}
      actions={<PublishStatus manifests={publishStatus} />}
    >
      <Flex wide>
        <FlexChild grow={2}>
          {isInvalidPublishManfest && (
            <Box my={2}>
              <Alert severity="error">
                <AlertTitle>This Frag Order is broken</AlertTitle>
                Due to an internal Frag Orders bug, this Frag Order may not
                behave properly. If you are using planning features, you may
                need to re-create this Frag Order. Please consult the{" "}
                <a href="https://discord.gg/bt9MmAJJ">
                  Frag Orders Discord
                </a>{" "}
                with any questions.
              </Alert>
            </Box>
          )}

          <SubRouterTabs rootPath={`${url}/details`}>
            <RouterTab name="Miz File" path={`${url}/details`}>
              <Box marginBottom={2}>
                <MizFileDetails fragOrder={fragOrder} />
                <Box marginTop={2}>
                  <JobsPage id={id} />
                </Box>
              </Box>
            </RouterTab>
            <RouterTab name="Publish" path={`${url}/publish`}>
              <Box marginBottom={2}>
                <PublishOptions
                  manifests={publishStatus}
                  fragOrder={fragOrder}
                />
              </Box>
            </RouterTab>

            <RouterTab subscriberOnly name="Planning" path={`${url}/planning`}>
              <PlanningManagementForm />
            </RouterTab>
            <RouterTab
              subscriberOnly
              path={`${url}/kneeboards`}
              name="Kneeboards"
            >
              <KneeboardManagement
                fragOrder={fragOrder}
                manifests={publishStatus}
              />
            </RouterTab>
            <RouterTab subscriberOnly path={`${url}/radios`} name="Radios">
              <AdminRadios />
            </RouterTab>
            <RouterTab
              subscriberOnly
              path={`${url}/drawing`}
              name="Control Measures"
            >
              <DrawingEditors />
            </RouterTab>
            <RouterTab
              subscriberOnly
              path={`${url}/external-data`}
              name="Batch Edit"
            >
              <ExternalData />
            </RouterTab>
            <RouterTab
              featureFlag={FeatureFlag.Signups}
              subscriberOnly
              path={`${url}/signups`}
              name="Signups"
            >
              <SignupManagement />
            </RouterTab>
          </SubRouterTabs>
        </FlexChild>
      </Flex>
    </Page>
  );
}

export default styled(FragOrderDetail).attrs({
  className: FragOrderDetail.name,
})`
  .MuiAvatar-root {
    height: 24px;
    width: 24px;

    padding-top: 6px;
  }

  .MuiBreadcrumbs-root {
    ${Link} {
      font-size: 1.5em;
    }

    h2 {
      margin: 0;
    }
  }

  .MuiButton-root {
    white-space: nowrap;
  }

  @media only screen and (max-width: 600px) {
    .SubRouterTabs {
      max-width: 100%;
      overflow-y: auto;
    }

    .MuiContainer-root {
      padding-left: 0px;
    }

    .SubRouterTabs > .MuiBox-root,
    .SubRouterTabs > .MuiBox-root > .MuiBox-root {
      padding-left: 0px;
    }

    .MuiBox-root {
      padding: 12px;
    }
    h2 {
      font-size: 18px;
    }

    td,
    th {
      white-space: nowrap;
      padding: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .miz-stat-value {
      max-width: 64px;
    }

    .MuiAutocomplete-root {
      min-width: 240px;
    }

    .MuiOutlinedInput-root {
      max-width: 240px;
    }

    .MuiTabs-scroller .MuiButtonBase-root {
      max-width: 100%;
      /* padding: 4px; */
      /* font-size: 12px; */
    }

    .MizFileDetails > .Flex:first-child > .MuiBox-root {
      /* padding: 0; */
      overflow: hidden;
    }
  }
`;
