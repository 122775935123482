import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  startAt,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { DateTime } from "luxon";
import { docs } from "../db_util";
import { FragOrderDoc } from "../models/FragOrder";
import { JobSettings, JobType } from "../models/Job";
import { MizFile } from "../models/MizFile";
import { defaultPublishOpts, PublishOpts } from "../models/PublishManifest";
import { Coalition } from "../types";
import { MizStorer } from "./MizStorer";
import { PublishManager, PublishManifestCollection } from "./PublishManager";

export const FragOrdersCollection = "FragOrders";

export const defaultJobSettings: Record<JobType, JobSettings> = {
  [JobType.GroupLineupCard]: {
    showTimeOnTarget: false,
    showDistance: false,
    showAltitude: true,
    showFreqs: true,
    showCoords: false,
    showSpeed: false,
  },
  [JobType.InjectAirbasesKneeboard]: {
    showAirbaseFreqs: true,
  },
  // @ts-ignore
  [JobType.ControlPointsCard]: {},
  // @ts-ignore
  [JobType.BriefingText]: {},
  // @ts-ignore
  [JobType.InjectSupportAssetsKneeboard]: {},

  [JobType.InjectRouteDetailCards]: {
    // @ts-ignore
    showBullsCompass: false,
    showDistances: true,
  },
};

class client implements FragOrderClient {
  db: Firestore;
  ms: MizStorer;
  pub: PublishManager;

  async get(id: string) {
    const docRef = doc(this.db, FragOrdersCollection, id);
    const docSnap = await getDoc(docRef);

    return { ...docSnap.data(), id } as FragOrderDoc;
  }

  async save(
    userID: string,
    name: string,
    miz: MizFile,
    discordGuildID: string
  ) {
    const t = new Date().getTime();

    const doc = {
      name,
      mizFileName: miz.file.name,
      created_by_uid: userID,
      created_at_timestamp: t,
      updated_at_timestamp: t,
      mizFileSize: miz.file.size,
      mizMetadata: miz.metadata,
      publishOpts: defaultPublishOpts(),
      discordGuildID,
    };

    const docref = await addDoc(collection(this.db, FragOrdersCollection), doc);

    await this.ms.save(docref.id, miz.file);

    return { ...doc, id: docref.id } as FragOrderDoc;
  }

  private pageSnaps: QueryDocumentSnapshot<DocumentData>[] = [];

  async list(userID: string, count: number = 10, page: number) {
    const args = [
      collection(this.db, FragOrdersCollection),
      where("created_by_uid", "==", userID),
      orderBy("created_at_timestamp", "desc"),
      limit(count),
    ];

    if (page) {
      const lastSnap = this.pageSnaps[page];
      if (lastSnap) {
        args.push(startAt(lastSnap));
      }
    }

    const q = query.call(this, ...args);

    const querySnapshot = await getDocs(q);
    this.pageSnaps[page + 1] = _.last(querySnapshot.docs);

    return docs<FragOrderDoc>(querySnapshot);
  }

  async publish(
    userID: string,
    fragOrder: FragOrderDoc,
    coalitions: Coalition[],
    opts: PublishOpts
  ) {
    await this.update(userID, coalitions, {
      ...fragOrder,
      publishOpts: opts,
      jobSettings: defaultJobSettings,
    });
  }

  async update(
    userID: string,
    coalitions: Coalition[],
    fragOrder: FragOrderDoc,
    miz?: MizFile
  ) {
    if (!coalitions) {
      throw new Error("coaltions was undefined or not iterable");
    }
    if (miz) {
      await this.ms.save(fragOrder.id, miz.file);
    }

    const next: FragOrderDoc = {
      ...fragOrder,
      jobSettings: {
        ...defaultJobSettings,
        ...fragOrder.jobSettings,
      },
      updated_at_timestamp: DateTime.now().toSeconds(),
    };

    await setDoc(doc(this.db, FragOrdersCollection, fragOrder.id), next);

    for (const coa of coalitions) {
      await this.pub.upsertPublishManifest(userID, coa, next, next.publishOpts);
    }

    // Note: optimistic update here
    return next as FragOrderDoc;
  }

  async remove(d: FragOrderDoc): Promise<void> {
    const manifests = await this.pub.getPublishStatus(d.id);

    for (const man of manifests) {
      await deleteDoc(doc(this.db, PublishManifestCollection, man.id));
    }

    return deleteDoc(doc(this.db, FragOrdersCollection, d.id));
  }

  async addJob(
    userID: string,
    fragOrderID: string,
    jobType: JobType,
    settings: JobSettings
  ): Promise<FragOrderDoc> {
    const fo = await this.get(fragOrderID);

    if (!fo) {
      throw new Error(`Frag Order ${fragOrderID} not found`);
    }

    const next = {
      ...fo,
      jobs: [...(fo.jobs || []), jobType],
      jobSettings: {
        ...fo.jobSettings,
        [jobType]: settings,
      },
    };

    // No need to update manifests currently.
    // That will change if we want to render a kneeboard based on external data.
    return this.update(userID, [], next);
  }

  async removeJob(
    userID: string,
    fragOrderID: string,
    jobType: JobType
  ): Promise<FragOrderDoc> {
    const fo = await this.get(fragOrderID);

    if (!fo) {
      throw new Error(`Frag Order ${fragOrderID} not found`);
    }

    const next = {
      ...fo,
      jobs: fo.jobs.filter((j) => j !== jobType),
      jobSettings: {
        ...fo.jobSettings,
        [jobType]: null,
      },
    };

    return this.update(userID, [], next);
  }
}

export interface FragOrderReader {
  get(id: string): Promise<FragOrderDoc>;
  list(userID: string, page?: number, limit?: number): Promise<FragOrderDoc[]>;
}

export interface FragOrderClient extends FragOrderReader {
  save(
    userID: string,
    name: string,
    miz: MizFile,
    discordGuildID: string
  ): Promise<FragOrderDoc>;

  publish(
    userID: string,
    fragOrder: FragOrderDoc,
    coalitions: Coalition[],
    opts: PublishOpts
  ): Promise<void>;

  update(
    userID: string,
    coalitions: Coalition[],
    doc: FragOrderDoc,
    miz?: MizFile
  ): Promise<FragOrderDoc>;
  remove(doc: FragOrderDoc): Promise<void>;

  addJob(
    userID: string,
    fragOrderID: string,
    jobType: JobType,
    settings: JobSettings
  ): Promise<FragOrderDoc>;

  removeJob(
    userID: string,
    fragOrderID: string,
    jobType: JobType
  ): Promise<FragOrderDoc>;
}

export function NewFragOrderClient(
  db: Firestore,
  ms: MizStorer,
  pub: PublishManager
): FragOrderClient {
  const c = new client();
  c.db = db;
  c.ms = ms;
  c.pub = pub;
  return c;
}
